$('[data-toggle="menu"]').click(function(){
	var alvo = $(this).data('target')

	$(alvo).toggleClass('active');
});

$('[data-toggle="active"]').click(function(){
	var alvo = $(this).data('target')
	$(alvo).toggleClass('active');
});

$('[data-toggle="tabs"]').click(function(){
	var alvo = $(this).data('target')
	$(alvo).toggleClass('active');
});

$('[data-toggle="popup"]').click(function(){
	var alvo = $(this).data('target')
	$(alvo).toggleClass('active');
});

$('[data-toggle="filtro"]').click(function(){
	$('[data-toggle="filtro"]').removeClass('active');
	$(this).toggleClass('active');
});
